var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plant-statistical-analysis"},[_c('div',{staticClass:"content-item"},[_vm._l((_vm.info),function(item,index){return _c('div',{key:index,staticClass:"stat-item"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"count"},[_c('p',{staticClass:"num",style:({ color: item.color })},[_vm._v(" "+_vm._s(item.count)+" ")]),_c('p',[_vm._v(_vm._s(item.name))])])])}),_c('div',{staticClass:"stat-item"},[_c('img',{attrs:{"src":"imgs/plant/plantArea.png","alt":""}}),_c('Select',{staticStyle:{"width":"200px"},model:{value:(_vm.farmId),callback:function ($$v) {_vm.farmId=$$v},expression:"farmId"}},_vm._l((_vm.farms),function(item){return _c('Option',{key:item.sourceId,attrs:{"value":item.sourceId}},[_vm._v(_vm._s(item.mapName))])}),1)],1)],2),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"fertilize-change"},[_c('div',{staticClass:"head-box"},[_c('p',{staticClass:"title"},[_vm._v("施肥变化")]),_c('div',{staticClass:"filter-box"},[_c('span',{class:[
              'filter-item',
              _vm.fertilizeType == 'week' ? 'filter-item-active' : '',
            ],on:{"click":() => _vm.fertilizeCut('week')}},[_vm._v("周")]),_c('span',{class:[
              'filter-item',
              _vm.fertilizeType == 'month' ? 'filter-item-active' : '',
            ],on:{"click":() => _vm.fertilizeCut('month')}},[_vm._v("月")]),_c('span',{class:[
              'filter-item',
              _vm.fertilizeType == 'year' ? 'filter-item-active' : '',
            ],on:{"click":() => _vm.fertilizeCut('year')}},[_vm._v("年")]),_c('DatePicker',{staticStyle:{"width":"200px"},attrs:{"type":"daterange","options":_vm.options},model:{value:(_vm.fertilizeDate),callback:function ($$v) {_vm.fertilizeDate=$$v},expression:"fertilizeDate"}})],1)]),_c('div',{ref:"fertilizeChart",staticClass:"fertilize-chart"})]),_c('div',{staticClass:"plant-record"},[_c('p',{staticClass:"record-title"},[_vm._v("种植记录")]),_vm._l((_vm.plantRecord),function(item,index){return _c('div',{key:index,staticClass:"record-item"},[_c('p',[_c('span',[_vm._v("种植作物：")]),_c('span',[_vm._v(_vm._s(item.categoryName))])]),_c('p',{staticClass:"plant-date"},[_vm._v(" "+_vm._s(item.createTime.split(" ")[0])+" ~ "+_vm._s(item.endTime ? item.endTime.split(" ")[0] : "至今")+" ")]),_c('div',{staticClass:"dot"})])}),(_vm.plantRecord.length == 0)?_c('div',{staticClass:"no-data"},[_c('img',{attrs:{"src":"imgs/empty/empty.png","alt":""}}),_c('p',[_vm._v("暂无种植记录")])]):_vm._e()],2)]),_c('div',{staticClass:"content-item"},[_c('div',{staticClass:"video-panel"},[_c('div',{staticClass:"video-title"},[_c('span',[_vm._v("监控视频")]),_c('Select',{staticStyle:{"width":"180px"},model:{value:(_vm.videoId),callback:function ($$v) {_vm.videoId=$$v},expression:"videoId"}},_vm._l((_vm.videoList),function(item,index){return _c('Option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1),(_vm.videoList.length > 0)?_c('div',{staticClass:"video-play"},[_c('player',{attrs:{"videoInfo":_vm.videoInfo}})],1):_c('div',{staticClass:"no-data"},[_c('img',{attrs:{"src":"imgs/empty/empty.png","alt":""}}),_c('p',[_vm._v("暂无数据")])])]),_c('div',{staticClass:"monitor-panel"},[_c('div',{staticClass:"head-box"},[_c('p',{staticClass:"title"},[_vm._v("种植场环境监测")]),_c('div',{staticClass:"filter-box"},[_c('span',{class:[
              'filter-item',
              _vm.monitorType == 'day' ? 'filter-item-active' : '',
            ],on:{"click":() => _vm.monitorCut('day')}},[_vm._v("今日")]),_c('span',{class:[
              'filter-item',
              _vm.monitorType == 'week' ? 'filter-item-active' : '',
            ],on:{"click":() => _vm.monitorCut('week')}},[_vm._v("本周")]),_c('span',{class:[
              'filter-item',
              _vm.monitorType == 'month' ? 'filter-item-active' : '',
            ],on:{"click":() => _vm.monitorCut('month')}},[_vm._v("本月")]),_c('span',{class:[
              'filter-item',
              _vm.monitorType == 'year' ? 'filter-item-active' : '',
            ],on:{"click":() => _vm.monitorCut('year')}},[_vm._v("本年")]),_c('DatePicker',{staticStyle:{"width":"200px"},attrs:{"type":"daterange","options":_vm.options,"placement":"left-start"},model:{value:(_vm.monitorDate),callback:function ($$v) {_vm.monitorDate=$$v},expression:"monitorDate"}})],1)]),_c('div',{staticClass:"monitor-data"},[(_vm.monitorData.length > 0)?_c('div',{staticClass:"data-block-box"},_vm._l((_vm.monitorData),function(item,index){return _c('div',{key:index,class:[
              'data-block',
              _vm.currentMonitor && _vm.currentMonitor.id == item.id
                ? 'data-block-active'
                : '',
            ],on:{"click":() => (_vm.currentMonitor = item)}},[_c('img',{attrs:{"src":_vm.getImgUrl(item.servicePath, item.imgUrl),"alt":""}}),_c('div',[_c('p',{staticClass:"value"},[_vm._v(_vm._s(item.measure))]),_c('p',{staticClass:"value-name"},[_vm._v(" "+_vm._s(`${item.sensorCategoryName}(${item.sensorCategoryUnit})`)+" ")])])])}),0):_vm._e(),(_vm.monitorData.length > 0)?_c('div',{ref:"monitorChart",staticClass:"monitor-chart"}):_c('div',{staticClass:"no-data"},[_c('img',{attrs:{"src":"imgs/empty/empty.png","alt":""}}),_c('p',[_vm._v("暂无数据")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }