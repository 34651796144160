<template>
  <div class="plant-statistical-analysis">
    <div class="content-item">
      <div class="stat-item" v-for="(item, index) in info" :key="index">
        <img :src="item.img" alt="" />
        <div class="count">
          <p class="num" :style="{ color: item.color }">
            {{ item.count }}
          </p>
          <p>{{ item.name }}</p>
        </div>
      </div>
      <div class="stat-item">
        <img src="imgs/plant/plantArea.png" alt="" />
        <Select v-model="farmId" style="width: 200px">
          <Option
            v-for="item in farms"
            :value="item.sourceId"
            :key="item.sourceId"
            >{{ item.mapName }}</Option
          >
        </Select>
      </div>
    </div>
    <div class="content-item">
      <div class="fertilize-change">
        <div class="head-box">
          <p class="title">施肥变化</p>
          <div class="filter-box">
            <span
              :class="[
                'filter-item',
                fertilizeType == 'week' ? 'filter-item-active' : '',
              ]"
              @click="() => fertilizeCut('week')"
              >周</span
            >
            <span
              :class="[
                'filter-item',
                fertilizeType == 'month' ? 'filter-item-active' : '',
              ]"
              @click="() => fertilizeCut('month')"
              >月</span
            >
            <span
              :class="[
                'filter-item',
                fertilizeType == 'year' ? 'filter-item-active' : '',
              ]"
              @click="() => fertilizeCut('year')"
              >年</span
            >
            <DatePicker
              type="daterange"
              style="width: 200px"
              :options="options"
              v-model="fertilizeDate"
            ></DatePicker>
          </div>
        </div>
        <div class="fertilize-chart" ref="fertilizeChart"></div>
      </div>
      <div class="plant-record">
        <p class="record-title">种植记录</p>
        <div
          class="record-item"
          v-for="(item, index) in plantRecord"
          :key="index"
        >
          <p>
            <span>种植作物：</span>
            <span>{{ item.categoryName }}</span>
          </p>
          <p class="plant-date">
            {{ item.createTime.split(" ")[0] }} ~
            {{ item.endTime ? item.endTime.split(" ")[0] : "至今" }}
          </p>
          <div class="dot"></div>
        </div>
        <div class="no-data" v-if="plantRecord.length == 0">
          <img src="imgs/empty/empty.png" alt="" />
          <p>暂无种植记录</p>
        </div>
      </div>
    </div>
    <div class="content-item">
      <div class="video-panel">
        <div class="video-title">
          <span>监控视频</span>
          <Select v-model="videoId" style="width: 180px">
            <Option
              v-for="(item, index) in videoList"
              :key="index"
              :value="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </div>
        <div class="video-play" v-if="videoList.length > 0">
          <player :videoInfo="videoInfo"></player>
        </div>
        <div class="no-data" v-else>
          <img src="imgs/empty/empty.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
      <div class="monitor-panel">
        <div class="head-box">
          <p class="title">种植场环境监测</p>
          <div class="filter-box">
            <span
              :class="[
                'filter-item',
                monitorType == 'day' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('day')"
              >今日</span
            >
            <span
              :class="[
                'filter-item',
                monitorType == 'week' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('week')"
              >本周</span
            >
            <span
              :class="[
                'filter-item',
                monitorType == 'month' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('month')"
              >本月</span
            >
            <span
              :class="[
                'filter-item',
                monitorType == 'year' ? 'filter-item-active' : '',
              ]"
              @click="() => monitorCut('year')"
              >本年</span
            >
            <DatePicker
              type="daterange"
              style="width: 200px"
              :options="options"
              v-model="monitorDate"
              placement="left-start"
            ></DatePicker>
          </div>
        </div>
        <div class="monitor-data">
          <div class="data-block-box" v-if="monitorData.length > 0">
            <div
              :class="[
                'data-block',
                currentMonitor && currentMonitor.id == item.id
                  ? 'data-block-active'
                  : '',
              ]"
              v-for="(item, index) in monitorData"
              :key="index"
              @click="() => (currentMonitor = item)"
            >
              <img :src="getImgUrl(item.servicePath, item.imgUrl)" alt="" />
              <div>
                <p class="value">{{ item.measure }}</p>
                <p class="value-name">
                  {{ `${item.sensorCategoryName}(${item.sensorCategoryUnit})` }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="monitor-chart"
            ref="monitorChart"
            v-if="monitorData.length > 0"
          ></div>
          <div class="no-data" v-else>
            <img src="imgs/empty/empty.png" alt="" />
            <p>暂无数据</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import variety from "../../../../public/imgs/plant/variety.png";
import area from "../../../../public/imgs/plant/area.png";
import block from "../../../../public/imgs/plant/block.png";
import plantArea from "../../../../public/imgs/plant/plantArea.png";
import moment from "moment";
import * as echarts from "echarts";
import player from "@/components/player";
export default {
  name: "",
  components: {
    player,
  },
  data() {
    return {
      options: {
        disabledDate(date) {
          if (moment(date).isAfter(moment(new Date()).subtract(1, "days")))
            return true;
          return false;
        },
      },
      farms: [],
      farmId: "",
      info: [
        { img: block, color: "#5480F9", name: "地块(块)", count: 0 },
        { img: area, color: "#9C54F9", name: "地块面积(㎡)", count: 0 },
        { img: variety, color: "#54B7F9", name: "种植品种(种)", count: 0 },
        { img: plantArea, color: "#CD54F9", name: "种植区(个)", count: 0 },
      ],
      fertilizeType: "year",
      fertilizeDate: [],
      fertilizeChart: null,

      monitorType: "year",
      monitorDate: [],
      monitorChart: null,
      videoList: [],
      videoInfo: null,
      videoId: "",
      plantRecord: [],

      monitorData: [],
      currentMonitor: null,
      statData: [],

      fertilizeChange: []
    };
  },
  watch: {
    farmId(n) {
      if (!n) return;
      this.getVideo();
      this.getCount();
      this.getPlantRecode();
      this.getMonitor()
      this.getFertilizeChange()
    },
    videoId(n) {
      this.videoInfo = this.videoList.filter((item) => item.id == n)[0];
    },
    currentMonitor() {
      this.getStatData();
    },
    monitorDate(n) {
      if (!n[0]) return;
      this.monitorType = "custom";
      this.getStatData();
    },
    fertilizeDate(n) {
      if (!n[0]) return
      this.fertilizeType = 'custom'
      this.getFertilizeChange()
    }
  },
  methods: {
    getCount() {
      this.$post(this.$api.STATICTICS_ANALYSIS.FARMING_STAT, {
        farmId: this.farmId,
        siteType: "1",
      }).then((res) => {
        this.info[0].count = res.sceneCount;
        this.info[1].count = res.area;
        this.info[2].count = res.productCount;
        this.info[3].count = res.farmCount;
      });
    },
    //获取基地
    getFarms() {
      this.$post(this.$api.MAP.MAP_CFG_INFO, {
        farmType: "1",
      }).then((res) => {
        if (res.list.length == 0) return;
        this.farms = res.list;
        this.farmId = res.list[0].sourceId;
      });
    },
    //获取视频
    getVideo() {
      this.$post(this.$api.BUSSDEVICEVIDEO.LIST, {
        farmId: this.farmId,
      }).then((res) => {
        if (res.list.length == 0) {
          this.videoList = [];
          this.videoId = "";
          this.videoInfo = null;
          return;
        }
        this.videoList = res.list;
        this.videoId = res.list[0].id;
        this.videoInfo = res.list[0];
      });
    },
    //获取种植记录
    getPlantRecode() {
      this.$post(this.$api.LIVESTOCK_ON_HAND.LIST, {
        siteType: "1",
        farmId: this.farmId,
        pageNo: 1,
        pageSize: 5,
      }).then((res) => {
        this.plantRecord = res.list;
      });
    },

    fertilizeCut(type) {
      if (this.fertilizeType == type) return
      this.fertilizeType = type
      this.getFertilizeChange()
    },

    getFertilizeChange() {
      this.$post(this.$api.STATICTICS_ANALYSIS.PLANT_FERTILIZATION_CHANGE, {
        farmId: this.farmId,
        startDate: this.fertilizeType == 'custom' ? moment(this.fertilizeDate[0]).format('YYYY-MM-DD') : '',
        endDate: this.fertilizeType == 'custom' ? moment(this.fertilizeDate[1]).format('YYYY-MM-DD') : '',
        type: this.fertilizeType
      }).then(res => {
        this.fertilizeChange = res
        this.initFertilizeChart();
      })
    },

    initFertilizeChart() {
      let xData = this.fertilizeChange.map(item => item.date)
      let data = this.fertilizeChange.map(item => item.count)
      if (!this.fertilizeChart) {
        this.fertilizeChart = echarts.init(this.$refs.fertilizeChart);
      }
      let option = {
        grid: {
          top: 20,
          left: 50,
          right: 50,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: xData,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        color: ["#7A40F2"],
        series: {
          type: "line",
          smooth: true,
          data,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(122, 64, 242, 0.4)",
                },
                {
                  offset: 1,
                  color: "rgba(122, 64, 242, 0)",
                },
              ],
            },
          },
          lineStyle: {
            shadowOffsetY: 10,
            shadowColor: "rgba(122, 64, 242, 0.3)",
            shadowBlur: 4,
          },
        },
      };
      this.fertilizeChart.setOption(option);
    },

    getMonitor() {
      this.$post(this.$api.MONITOR.TREATMENT_EFFECT_MONITOR, {
        farmId: this.farmId,
      }).then((res) => {
        this.monitorData = res.list;
        this.currentMonitor = res.list[0];
      });
    },
    getStatData() {
      if (!this.currentMonitor) return;
      this.$post(this.$api.MONITOR.MONITOR_CHART_NEW, {
        guid: this.currentMonitor.guid,
        switchType: this.monitorType,
        sBeginTime:
          this.monitorType == "custom"
            ? moment(this.monitorDate[0]).format("YYYY-MM-DD")
            : "",
        sEndTime:
          this.monitorType == "custom"
            ? moment(this.monitorDate[1]).format("YYYY-MM-DD")
            : "",
        days:
          this.monitorType == "week"
            ? 7
            : this.monitorType == "month"
            ? 30
            : this.monitorType == "custom"
            ? moment(this.monitorDate[1]).diff(
                moment(this.monitorDate[0]),
                "days"
              ) + 1
            : "",
      }).then((res) => {
        this.statData = res || [];
        this.initMonitorChart();
      });
    },
    monitorCut(type) {
      if (this.monitorType == type) return;
      this.monitorType = type;
      if (!this.farmId) return;
      this.getStatData();
    },
    initMonitorChart() {
      let xData = this.statData[0].child.map((el) => el.showAllTime);
      let data = this.statData[0].child.map((el) => el.value);
      if (!this.monitorChart) {
        this.monitorChart = echarts.init(this.$refs.monitorChart);
      }
      let option = {
        grid: {
          top: 20,
          left: 50,
          right: 50,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: xData,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        color: ["#7A40F2"],
        series: {
          type: "line",
          smooth: true,
          data,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(122, 64, 242, 0.4)",
                },
                {
                  offset: 1,
                  color: "rgba(122, 64, 242, 0)",
                },
              ],
            },
          },
          lineStyle: {
            shadowOffsetY: 10,
            shadowColor: "rgba(122, 64, 242, 0.3)",
            shadowBlur: 4,
          },
        },
      };
      this.monitorChart.setOption(option);
    },

    resize() {
      this.fertilizeChart.resize();
      this.monitorChart.resize();
    },
  },
  mounted() {
    this.getFarms();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>